(function (emem, $, window, document) {
  "use strict";
  //## GENERAL Scripts
  var generalModule = (function () {
    var GLOBS = {
      hamburger: document.getElementById("hamburger"),
      body: document.getElementsByTagName("body"),
      header: document.getElementsByTagName("header"),
      cookiePopup: document.getElementById("cookie_popup"),
      acceptCookie: document.getElementById("accept_cookie"),
      slideshowMain: document.getElementById("slideshow_main"),
      slideshowSection: document.getElementsByClassName("slideshow_section"),
      popUpCloseButton: document.getElementById("property_popup__close_button"),
      propertyItem: document.getElementsByClassName("locations_section__item"),
    };
    //Binds all handle functions to a new function which is called @init
    var bindEvents = function () {
      function findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
      }

      if (GLOBS.hamburger) {
        GLOBS.hamburger.addEventListener("click", toggleHamburger);

        function toggleHamburger(el) {
          this.classList.toggle("active");
          GLOBS.body[0].classList.toggle("activeNav");
          GLOBS.header[0].classList.toggle("activeNav");
        }
      }

      if (GLOBS.popUpCloseButton) {
        GLOBS.popUpCloseButton.addEventListener("click", closePopUp);

        function closePopUp(el) {
          GLOBS.body.classList.remove("activePopUp");
        }
      }

      if (GLOBS.slideshowSection) {
        var items = GLOBS.slideshowSection;

        function changeSlide(images) {
          var current, next;

          for (var j = 0; j < images.length; j++) {
            if (images[j].classList.contains("active")) {
              current = images[j];
              next = j == images.length - 1 ? images[0] : images[j + 1];
            }
          }

          current.classList.remove("active");
          next.classList.add("active");
        }

        function initSlideshow(element) {
          var delay = element.getAttribute("data-slide-delay"),
            images = element.querySelectorAll(".slideshow_section__thumb");

          setInterval(function () {
            changeSlide(images);
          }, delay * 1000);
        }

        for (var i = 0; i < items.length; i++) {
          initSlideshow(items[i]);
        }
      }

      function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == " ") c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0)
            return c.substring(nameEQ.length, c.length);
        }
        return null;
      }
      function setCookie(name, value, days) {
        var expires = "";
        if (days) {
          var date = new Date();
          date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
          expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
      }

      var handleCookiePopup = function () {
        GLOBS.cookiePopup.classList.remove("active");
        setCookie("hasAcceptedCookie", true, 9999);
      };
      //Cookie Popup
      if (getCookie("hasAcceptedCookie") == null) {
        GLOBS.cookiePopup.classList.add("active");
        GLOBS.acceptCookie.addEventListener("click", handleCookiePopup);
      }

      if (GLOBS.propertyItem) {
        for (var i = 0; i < GLOBS.propertyItem.length; i++) {
          GLOBS.propertyItem[i].addEventListener("click", function (e) {
            GLOBS.body.classList.add("activePopUp");
          });
        }
      }

      // Select all links with hashes
      $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function (event) {
          // On-page links
          if (
            location.pathname.replace(/^\//, "") ==
              this.pathname.replace(/^\//, "") &&
            location.hostname == this.hostname
          ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length
              ? target
              : $("[name=" + this.hash.slice(1) + "]");
            // Does a scroll target exist?
            if (target.length) {
              // Only prevent default if animation is actually gonna happen
              event.preventDefault();
              $("html, body").animate(
                {
                  scrollTop: target.offset().top - 20, //GLOBS.header.outerHeight()
                },
                1000,
                function () {
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) {
                    // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  }
                }
              );
            }
          }
        });
      // Object Fit for IE
      function ieObjectFit() {
        $(".ie_object_fit").each(function () {
          var $container = $(this),
            img = $container.find("img"),
            imgUrl = img.prop("src");
          if (imgUrl) {
            img.css("opacity", "0");
            $container
              .css("backgroundImage", "url(" + imgUrl + ")")
              .addClass("custom-object-fit");
          }
        });
      }
      if (
        /Edge/.test(navigator.userAgent) ||
        navigator.appName == "Microsoft Internet Explorer" ||
        !!(
          navigator.userAgent.match(/Trident/) ||
          navigator.userAgent.match(/rv:11/)
        ) ||
        (typeof $.browser !== "undefined" && $.browser.msie == 1)
      ) {
        $(".thumb_holder").addClass("ie_object_fit");
        ieObjectFit();

        $("html").addClass("ie");
      }
    };
    //End BIND
    //Inits Functions and Events
    var init = function () {
      bindEvents();
    };
    //END INIT
    //Inits Plugins
    var initPlugins = function () {
      if (
        GLOBS.slideshowMain &&
        GLOBS.slideshowMain.getAttribute("data-slides-total") > 1
      ) {
        $(".slideshow_main").owlCarousel({
          items: 1,
          margin: 0,
          loop: true,
          nav: false,
          dots: false,
          autoplay: true,
          autoplayTimeout: 6000,
        });
      }
    };

    //END INIT PLUGINS
    return {
      init: init,
      initPlugins: initPlugins,
    };
  })();
  //## END GENERAL Scripts
  //## emem MAIN
  var ememMain = (function () {
    var init = function () {
      generalModule.init();
      generalModule.initPlugins();
    };
    return {
      init: init,
    };
  })();
  //## END emem MAIN
  //Doc Ready
  document.addEventListener(
    "DOMContentLoaded",
    function () {
      ememMain.init();
    },
    false
  );
})((window.emem = window.emem || {}), window.jQuery, window, document);
